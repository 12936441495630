var jQuery = $;
import slick from "../../node_modules/slick-carousel/slick/slick.min.js";
import match from "../../node_modules/jquery-match-height/jquery.matchHeight.js";
import fancybox from "../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min.js";
const app = {
    /**
     * Initialize.
     */
    init() {
        // Initialize sliders
        this.initSliders();

        // Show more function
        this.showMoreBlogs();
        this.showMoreCases();

        // Mobile Navigation
        this.openNavigation();

        // Sticky MenuCard Bar
        this.stickyMenuCardBar();

        // Smooth scroll
        this.scrollSmooth();

        // File upload check
        this.fileUploadCheck();

        // Toggle cookie settings
        this.toggleCookieSettings();

        // Register our event listeners
        $(window).on("scroll", $.proxy(this.windowScrollHandler, this));
        $(".hamburger").on("click", $.proxy(this.toggleHamburgerMenu, this));
        $('a[href="#"]').on("click", $.proxy(this.scrollToAnchor, this));
        $(".overlay, .closebrochurepopup, .closeoffertepopup").on(
            "click",
            $.proxy(this.closeModal, this)
        );
        $(".case-overview .image").click((e) =>
            $(".folding-menu").toggleClass("active")
        );
        $(".pageheader.menu .image").click((e) =>
            $(".folding-menu").toggleClass("active")
        );
        $('main .button[href^="#offerte"], main .button[href^="#brochure"]').on(
            "click",
            $.proxy(this.openModal, this)
        );
        this.prefillPopup();
        // direct browser to top right away
        if (window.location.hash) scroll(0, 0);
        // takes care of some browsers issue
        setTimeout(function () {
            scroll(0, 0);
        }, 1);
    },

    /**
     * Scroll to anchor.
     *
     * @param {event} e
     */
    scrollToAnchor(e) {
        // $('html, body').animate({scrollTop: $(e.currentTarget).offset().top - 50}, 800, () => window.location.hash = hash);
    },

    /**
     * Handles the scroll event.
     *
     * @param {event} e
     */
    windowScrollHandler(e) {
        $("header").toggleClass("scrolled", $(window).scrollTop() > 45);
    },

    /**
     * Open hamburger menu.
     *
     * @param {event} e
     */
    toggleHamburgerMenu(e) {
        $(e.currentTarget).toggleClass("is-active");
        $("nav.navigation").toggleClass("visible");
    },

    /**
     * Open modal on event.
     *
     * @param {event} e
     */
    openModal(e) {
        $('[data-modal="' + $(e.currentTarget).attr("href") + '"]').addClass(
            "active"
        );
        $("body").addClass("scroll");
    },

    /**
     * Close modal on event.
     *
     * @param {event} e
     */
    closeModal(e) {
        $(".offertepopup, .brochurepopup").removeClass("active");
        $("body").removeClass("scroll");
    },

    /**
     * Prefill popup based on body class.
     *
     */
    prefillPopup() {
        var id = parseInt(
            $("body").attr("class").split(" ")[2].replace("page-id-", "")
        );
        var options = {
            427: "Personeelsfeest",
            429: "Bedrijfsfeest",
            431: "Walking en seated diners",
            433: "Seminaries & Presentaties",
            435: "Productlancering",
            437: "Modeshows",
            439: "Event op maat",
        };
        $("#input_5_8").val(options[id]);
    },

    /**
     * Init sliders.
     *
     */
    initSliders() {
        if ($(".logoslider .container").length) {
            $(".logoslider .container").slick({
                autoplay: true,
                autoplaySpeed: 3000,
                slidesToShow: 3,
                mobileFirst: true,
                responsive: [
                    { breakpoint: 768, settings: { slidesToShow: 4 } },
                    { breakpoint: 1024, settings: { slidesToShow: 5 } },
                    { breakpoint: 1280, settings: { slidesToShow: 6 } },
                    { breakpoint: 1440, settings: { slidesToShow: 8 } },
                ],
            });
        }

        // Config image-content slider
        if ($(".image-content-slider .content").length) {
            $(".image-content-slider .content").slick({
                autoplay: false,
                autoplaySpeed: 3000,
                slidesToShow: 1,
                fade: true,
                dots: true,
                asNavFor: ".image-content-slider .image",
            });
        }

        if ($(".image-content-slider .image").length) {
            $(".image-content-slider .image").slick({
                autoplay: false,
                autoplaySpeed: 3000,
                slidesToShow: 1,
                dots: false,
                arrows: false,
                asNavFor: ".image-content-slider .content",
            });
        }

        // Config image-content-2 slider
        if ($(".slider-image-fixed-content .image").length) {
            $(".slider-image-fixed-content .image").slick({
                autoplay: false,
                autoplaySpeed: 3000,
                slidesToShow: 1,
                dots: true,
                arrows: true,
            });
        }

        // Config single-post slider
        if ($(".slider .images").length) {
            $(".slider .images").slick({
                autoplay: false,
                autoplaySpeed: 3000,
                slidesToShow: 1,
                dots: true,
                arrows: true,
            });
        }

        // Config review slider
        if ($(".reviewslider .container.slider").length) {
            $(".reviewslider .container.slider").slick({
                autoplay: false,
                autoplaySpeed: 3000,
                slidesToShow: 1,
                dots: true,
                arrows: false,
                mobileFirst: true,
                responsive: [
                    { breakpoint: 768, settings: { slidesToShow: 2 } },
                ],
            });
        }

        // Config review slider
        if ($(".container.slider2").length) {
            $(".container.slider2").slick({
                autoplay: false,
                autoplaySpeed: 3000,
                slidesToShow: 1,
                dots: true,
                arrows: false,
                mobileFirst: true,
                responsive: [
                    { breakpoint: 768, settings: { slidesToShow: 1 } },
                ],
            });
        }

        // Config groups slider
        if ($(".group-holder").length) {
            $(".group-holder").slick({
                autoplay: false,
                slidesToShow: 3,
                dots: false,
                arrows: true,
                prevArrow: $(".group-arrow.prev"),
                nextArrow: $(".group-arrow.next"),
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            dots: true,
                            arrows: false,
                        },
                    },
                ],
            });
        }

        // Config events slider
        if ($(".events-holder").length) {
            $(".events-holder").slick({
                autoplay: false,
                slidesToShow: 3,
                dots: false,
                arrows: true,
                prevArrow: $(".event-arrow.prev"),
                nextArrow: $(".event-arrow.next"),
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 2,
                        },
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            dots: true,
                            infinite: true,
                        },
                    },
                ],
            });
        }

        // Hero slider
        if ($(".pagehero .slider").length) {
            $(".pagehero .slider").slick({
                autoplay: true,
                slidesToShow: 1,
                dots: true,
                arrows: false,
                fade: true,
            });
        }

        // Media slider
        if ($(".media-holder .media .slider").length) {
            $(".media-holder .media .slider").slick({
                autoplay: true,
                slidesToShow: 1,
                dots: true,
                arrows: false,
                fade: true,
            });
        }
    },

    /**
     * Show more function
     *
     */
    showMoreBlogs() {
        $(".loadmoreblogs").on("click", function (e) {
            e.preventDefault();
            $(this)
                .parent()
                .parent()
                .parent()
                .find($(".col:hidden"))
                .slice(0, 3)
                .slideDown()
                .css("display", "flex");
        });
    },
    showMoreCases() {
        $(".loadmorecases").on("click", function (e) {
            e.preventDefault();
            $(this)
                .parent()
                .parent()
                .parent()
                .find($(".col:hidden"))
                .slice(0, 2)
                .slideDown()
                .css("display", "flex");
        });
    },

    /** Mobile navigation */
    openNavigation() {
        if ($(window).width() < 1025) {
            $("li.has-children a.navlink").on("click", function (e) {
                e.preventDefault();
                var $this = $(this);

                if ($this.parent().hasClass("is-opened")) {
                    $this.parent().removeClass("is-opened");
                } else {
                    $this.parent().toggleClass("is-opened");
                }
            });

            $("li.has-children ul li a").on("click", function () {
                window.location.href = $(this).attr("href");
            });
        }
    },

    stickyMenuCardBar() {
        $.fn.isInViewport = function () {
            var elementTop = $(this).offset().top;
            var elementBottom = elementTop + $(this).outerHeight();

            var viewportTop = $(window).scrollTop();
            var viewportBottom = viewportTop + $(window).height();

            return elementBottom > viewportTop && elementTop < viewportBottom;
        };

        $(window).on("resize scroll", function () {
            $(".menucard").each(function () {
                var activeMenucard = $(this).attr("id");
                if ($(this).isInViewport()) {
                    $("#item-" + activeMenucard).addClass(
                        activeMenucard + " is-active"
                    );
                } else {
                    $("#item-" + activeMenucard).removeClass(
                        activeMenucard + " is-active"
                    );
                }
            });
        });
    },

    /** Smooth Scroll */
    scrollSmooth() {
        $(".sticky-footer a.item").on("click", function (e) {
            // e.preventDefault();

            var target = this.hash,
                $target = $(target);

            $("html, body")
                .stop()
                .animate(
                    {
                        scrollTop: $target.offset().top - 70,
                    },
                    900,
                    "swing",
                    function () {
                        window.location.hash = target;
                    }
                );
        });

        $(".navigation a.navlink").on("click", function (e) {
            var target = this.hash,
                $target = $(target);

            if (target) {
                $("html, body")
                    .stop()
                    .animate(
                        {
                            scrollTop: $target.offset().top - 70,
                        },
                        900,
                        "swing",
                        function () {
                            window.location.hash = target;
                        }
                    );
            }
        });

        //smoothscroll navigation
        $(function () {
            if (window.location.hash) {
                $("html,body").animate(
                    {
                        scrollTop: $(window.location.hash).offset().top - 70,
                    },
                    900,
                    "swing"
                );
            }
        });
    },

    fileUploadCheck() {
        $('li.gfield.is-upload input[type="file"]').on("change", function () {
            if ($('input[type="file"]').val()) {
                $("li.gfield.is-upload .gfield_label").toggleClass("has-file");
            }
        });
    },

    /** Toggle Cookie pop-up settings */
    toggleCookieSettings() {
        if ($('#cookie-modal').length) {
            if (!Cookies.get("cookieSettings") && !Cookies.get('cookiesDenied')) {
                if ($('#cookie-modal').length) {
                    $.fancybox.open({
                        buttons: [],
                        src: "#cookie-modal",
                        type: "inline",
                        closeClick: false,
                        touch: false,
                        clickSlide: false,
                        ShowCloseButton: false,
                        helpers: {
                            overlay: {closeClick: false},
                        },
                        afterClose: function (e) {
                            $.fancybox.close();
                        },
                        mobile: {
                            clickSlide: false,
                            touch: false
                        },
                    });

                    $('body').addClass('disableScroll scroll');


                    $('a[href="#cookiepreference"]').on("click", function (e) {
                        e.preventDefault();
                        $(".cookie-settings").slideToggle(500).toggleClass("is-hidden");
                    });

                    $(".for-cookie-modal .accept").on("click", function () {
                        // Set the cookie when the close button is clicked
                        Cookies.set("cookieSettings", "accept", {});
                        $.fancybox.close();
                        location.reload(true);
                    });

                    $('a[href="#cookiedeny"]').on("click", function () {
                        Cookies.set("cookiesDenied", "true", {expires: 1});
                        $.fancybox.close();
                        location.reload(true);
                    });
                }
            }
        }
    },
};
$(document).ready($.proxy(app.init, app));